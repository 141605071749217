import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";

const FranchiseForm = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    franchiseLocation: "",
    city: "",
    experience: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <Container className="mt-5 franchise">
      {/* Add the action attribute with your PHP file URL */}
      <Form
        action="https://theroyalchitran.co.in/mail/submit.php"
        method="POST"
        onSubmit={() => {}}
      >
        <Row>
          <Col lg={6}>
            <Form.Group className="form-in" controlId="formBasicName">
              <Form.Control
                type="text"
                placeholder="Enter your Full Name"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="form-in" controlId="formBasicEmail">
              <Form.Control
                type="email"
                placeholder="Enter your Email Address"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="form-in" controlId="formBasicPhone">
              <Form.Control
                type="tel"
                placeholder="Enter your Phone Number"
                name="phone"
                value={formData.phone}
                pattern="[0-9]{10}"
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="form-in" controlId="formBasicFranchiseLocation">
              <Form.Control
                type="text"
                placeholder="Preferred Franchise Location"
                name="franchiseLocation"
                value={formData.franchiseLocation}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="form-in" controlId="formBasicCity">
              <Form.Control
                type="text"
                placeholder="Enter your City"
                name="city"
                value={formData.city}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="form-in" controlId="formBasicExperience">
              <Form.Control
                type="text"
                placeholder="Experience in Business/Franchising"
                name="experience"
                value={formData.experience}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col lg={12}>
            <Form.Group className="form-in" controlId="formBasicTextarea">
              <Form.Control
                as="textarea"
                rows={4}
                placeholder="Send Message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Button variant="primary" type="submit" className="w-100 sub">
            Submit
          </Button>
        </Row>
      </Form>
    </Container>
  );
};

export default FranchiseForm;
