import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Home from "../components/pages/Home";
import SchoolPicnic from '../components/pages/SchoolPicnic';
import CorporateEvent from '../components/pages/CorporateEvent';
import PrePostWedding from './pages/Photoshoot';
import FilmShooting from '../components/pages/FilmShooting';
import ContactUs from '../components/pages/ContactUs';
import CulturalPackage from '../components/pages/CulturalPackage';
import AdvertiseWithUs from '../components/pages/AdvertiseWithUs';

const Footer = () => {
  return (
    <footer>
      <div className='ftr-img img1'></div>
      <div className='ftr-img img4'></div>
      <div className='ftr-img img5'></div>
      <div className='ftr-img img3a'></div>
      <div className='ftr-img img3b'></div>
      <Container>
        <div className='sponsored-logos'>
        <div className='sponsored-logos-in'>
            <div className='clm1'>
              <div className='sponsored-wrap clearfix'>
                <div className='cl'><h3>Sponsored - </h3></div>
                <div className='cl'><Image src="./images/logo-sony-center.png" /><p>Anna Nagar</p></div>
                <div className='cl'><Image src="./images/logo-bosch.png" /><p>Anna Nagar</p></div>
                <div className='cl'><Image src="./images/logo-daikin.png" /><p>Anna Nagar</p></div>
                <div className='cl'><Image src="./images/logo-vigneto.png" /><p>Anna Nagar</p></div>
              </div>        
              <div className='sponsored-wrap2'>
                <div className='cl'><h4>Location</h4></div>
                <div className='cl'>Anna Nagar</div>
                <div className='cl'>Anna Nagar</div>
                <div className='cl'>Anna Nagar</div>
                <div className='cl'>Anna Nagar</div>
              </div>                
            </div>
            <div className='clm2'>
              <div className='more-info'>
                <p>For more info:</p>
                <div className="ftr-contact-wrap">
                  <div className='btn-whatsapp'><a href='https://wa.me/7667052339' target='_blank'><Image src="../images/ic-whatsapp3.png" /> 7667052339</a></div>
                </div>
              </div>
            
            </div>
            </div>
            <div className='checkout-row'>
              <a href='javascript:;'>Check Out Our New Low Price</a>
            </div>
        </div>
        <Row className='ftr-btm'>
          <Col lg={3} className='ftr-dt logo'>
          <a href='/'><figure className='ftr-logo'><Image src="./images/logo-ftr.png" /></figure></a>
          </Col>
          <Col lg={2} md={6} className='pt'>
            <h3>Quick links</h3>
            <ul className='links'>
              <li><a href='/' className='hover-1'>Home</a></li>
              <li><a href='/GoogleReview' className='hover-1'>Google Review</a></li>
              <li><a href='/Packages' className='hover-1'>Packages</a></li>
              <li><a href='/GroupPackage' className='hover-1'>Group Package</a></li>
              <li><a href='/SchoolPicnic' className='hover-1'>School Picnic</a></li>
              <li><a href='/Photoshoot' className='hover-1'>Photoshoot</a></li>
              <li><a href='/ContactUs' className='hover-1'>Contact Us</a></li>  
              <li><a href='/KeyInformation' className='hover-1'>Key Information</a></li>
              <li><a href='/AdvertiseWithUs' className='hover-1'>Advertise with us</a></li>
            </ul> 
          </Col>
          <Col lg={3} md={6} className='pt'>
            <h3>Address</h3>
            <p>NH-4, Near Queensland Theme Park, Kevlur Road Tandalam, Sriperumbudur,<br />Chennai, Tamil Nadu 602105</p>
            <div className='btn-direction'><a href='https://maps.app.goo.gl/YU6XZcUxq39hZkD67' target='_blank'><Image src="../images/ic-direction.png" /> Direction</a></div>
          </Col>
          <Col lg={4} className='pt last'>
            <h3>Whatsapp</h3>
            <div className="ftr-contact-wrap">
              <div className='btn-whatsapp'><a href='https://wa.me/9884334346' target='_blank'><Image src="../images/ic-whatsapp3.png" /> 9884334346</a></div>
              <div className='email'>Email - <a href='mailto:trcnh4chennai@gmail.com' target='_blank'>trcnh4chennai@gmail.com</a></div>
            </div>
          </Col>
        </Row>
      </Container>
       <a href='/'><figure className='ftr-logo-mbl'><Image src="./images/logo-ftr.png" fluid /></figure></a>
   <div className='ftr-btm2'>
    <Container>
     <Row>
      <Col sm={6}>
        <p>©2025 ALL RIGHTS RESERVED.</p>
      </Col>
      <Col sm={6}>
        <ul className='links2'>
          <li><a href='/PrivacyPolicy'>Privacy Policy</a></li>
          <li><a href='/TermsAndConditions'>T&C</a></li>
        </ul>
      </Col>
     </Row>
    </Container>
   </div>
    </footer>

  );
};

export default Footer;