import React from "react";
// import franchising from "../images/banner_franchising.png";
import './Banner.css';

function Banner() {
  return (
    <div className="ban-franchising">
      {/* <img src={franchising} alt="Franchising Banner" /> 
      <img src="/images/banner_franchising.png" alt="Franchising Banner" />*/}
    </div>
  );
}

export default Banner;
