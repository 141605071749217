import React from 'react';
import {Container, Row, Col, Image} from 'react-bootstrap';

const TermsAndConditions = () => {
  return (
    <section className="sp-cnt-blk">
      <Container>
        <div className='sp-cnt-in photoshoot text-center'>
          <div className='sp-cnt-in2'>
          <div className='hdg'><figure><Image src="../images/terms-hdg.png" fluid  /></figure></div>
          <div className='pre-blk'>
            <div className='privacy-blk'>
              <h2>Acceptance of Terms</h2>
                <ul>
                  <li>By accessing and using [The Royal Chitran] (the "https://theroyalchitran.co.in"), you agree to be bound by these Terms and Conditions ("Terms"). If you do not agree with any part of these Terms, you must not use this Website.</li>
                </ul>
              <h2>Use of the Website</h2>
                <ul>
                  <li>You agree to use the Website only for lawful purposes.</li>
                  <li>You agree not to engage in any activity that could damage, disable, or impair the Website or interfere with any other user's access.</li>
                  <li>Internet and network activity like IP addresses and location We collect this data automatically when you visit our website. This data is used to analyze location-based aggregate data and sources of traffic. We also use this data to provide goods or services and targeted promotions.</li>
                  <li>You agree not to attempt to gain unauthorized access to any portion of the Website, its systems, or networks.</li>
                  <li>You agree not to use any automated system or software to extract data from this website.</li>
                </ul>
                <h2>Intellectual Property</h2>
                <ul>
                  <li>All content on this Website, including text, graphics, logos, images, and software, is the property of [The Royal Chitran] or its licensors and is protected by copyright and other intellectual property laws.</li>
                  <li>You may not reproduce, distribute, or modify any content from this Website without our express written permission.</li>
                </ul>
                <h2>Disclaimer of Warranties</h2>
                <ul>
                  <li>The Website is provided "as is" and "as available" without any warranties, express or implied.</li>
                  <li>The Royal Chitran does not warrant that the Website will be uninterrupted, error-free, or free of viruses or other harmful components.</li>
                  <li>The Royal Chitran disclaims all warranties of merchantability and fitness for a particular purpose.</li>
                </ul>
                <h2>Limitation of Liability</h2>
                <ul>
                  <li>The Royal Chitran shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising from your use of the Website.</li>
                  <li>This limitation of liability applies to all claims, whether based on warranty, contract, tort, or any other legal theory.</li>
                </ul>
                <h2>Changes to Terms</h2>
                <ul>
                  <li>The Royal Chitran reserves the right to modify these Terms at any time. Any changes will be posted on this page.</li>
                  <li>Your continued use of the Website after any changes constitutes your acceptance of the new Terms.</li>
                </ul>
            </div>
          </div>
        </div>
        </div>
      </Container>
    </section>      
  )
}

export default TermsAndConditions