import React, { useState } from "react";
import { Container, Nav, Navbar, Image } from "react-bootstrap";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  NavLink
} from "react-router-dom";

import Home from "../components/pages/Home";
import GoogleReview from "../components/pages/GoogleReview";
import Packages from "../components/pages/Packages";
import GroupPackage from "../components/pages/GroupPackage";
import SchoolPicnic from "../components/pages/SchoolPicnic";
import Photoshoot from "../components/pages/Photoshoot";
import ContactUs from "../components/pages/ContactUs";
import KeyInformation from "../components/pages/KeyInformation";
import AdvertiseWithUs from "../components/pages/AdvertiseWithUs";
import Pongal from "../components/pages/Pongal";
import Holi from "../components/pages/Holi";
import Franchise from "../components/pages/Franchise";
import ThankYou from "../components/pages/ThankYou";
import PrivacyPolicy from "../components/pages/PrivacyPolicy";
import TermsAndConditions from "../components/pages/TermsAndConditions";

const NavBar = () => {
  const [expanded, setExpanded] = useState(false);
  const handleNavSelect = () => {
    setExpanded(false);
  };

  const handleLinkClick = () => {
    if (window.innerWidth <= 768) {
      setExpanded(!expanded);
    } else {
    }
  };

  return (
    <Router>
      <Navbar expanded={expanded} expand="xl" onSelect={handleNavSelect}>
        <Container fluid>
          <div className="logo-mbl">
            <a href="/">
              <Image src="/images/logo.png" alt="Logo" fluid />
            </a>
          </div>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={() => setExpanded(!expanded)}
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="justify-content-center">
              <div className="left">
                <NavLink
                  as={Link}
                  to={"/GoogleReview"}                  
                  onClick={handleLinkClick}
                >
                  Google Review
                </NavLink>
                <NavLink
                  as={Link}
                  to={"/Packages"}                  
                  onClick={handleLinkClick}
                >
                  Packages
                </NavLink>
                <NavLink
                  as={Link}
                  to={"/GroupPackage"}                  
                  onClick={handleLinkClick}
                >
                  Group Package
                </NavLink>
                <NavLink
                  as={Link}
                  to={"/SchoolPicnic"}                  
                  onClick={handleLinkClick}
                >
                  School Picnic
                </NavLink>
              </div>
              <Navbar.Brand href="/">
              <Image src="../images/logo.png" alt="Logo" fluid />
              </Navbar.Brand>
              <div className="right">
                <NavLink
                  as={Link}
                  to={"/Photoshoot"}
                  onClick={handleLinkClick}
                >
                  Photoshoot
                </NavLink>
                <NavLink
                  as={Link}
                  to={"/Franchise"}                  
                  onClick={handleLinkClick}
                >
                  Franchise
                </NavLink>
                <NavLink
                  as={Link}
                  to={"/AdvertiseWithUs"}
                  onClick={handleLinkClick}
                >
                  Advertise with us
                </NavLink>
                <NavLink
                  as={Link}
                  to={"/ContactUs"}
                  onClick={handleLinkClick}
                >
                  Contact Us
                </NavLink>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/GoogleReview" element={<GoogleReview />} />
          <Route path="/Packages" element={<Packages />} />
          <Route path="/GroupPackage" element={<GroupPackage />} />
          <Route path="/SchoolPicnic" element={<SchoolPicnic />} />
          <Route path="/Photoshoot" element={<Photoshoot />} />
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path="/KeyInformation" element={<KeyInformation />} />
          <Route path="/AdvertiseWithUs" element={<AdvertiseWithUs />} />
          <Route path="/Franchise" element={<Franchise />} />
          <Route path="/ThankYou" element={<ThankYou />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/TermsAndConditions" element={<TermsAndConditions />} />

         {/* <Route path="/" element={<MaintancePackage />} />
          <Route path="/SchoolPicnic" element={<MaintancePackage />} />
          <Route path="/CorporateEvent" element={<MaintancePackage />} />
          <Route path="/PrePostWedding" element={<MaintancePackage />} />
          <Route path="/FilmShooting" element={<MaintancePackage />} />
          <Route path="/ContactUs" element={<MaintancePackage />} />
          <Route path="/CulturalPackage" element={<MaintancePackage />} /> */}

        </Routes>
      </div>
    </Router>
  );
};

export default NavBar;