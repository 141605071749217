import React from 'react';
import {Container, Row, Col, Image} from 'react-bootstrap';

const PrivacyPolicy = () => {
  return (
    <section className="sp-cnt-blk">
      <Container>
        <div className='sp-cnt-in photoshoot text-center'>
          <div className='sp-cnt-in2'>
          <div className='hdg'><figure><Image src="../images/privacy-policy-hdg.png" fluid  /></figure></div>
          <div className='pre-blk'>
            <div className='privacy-blk'>
              <h2>Data Sources and Uses </h2>
              <ul>
                <li>Name, Email ID, Shipping and Billing Address, Phone Number This data is collected directly from users of the website. This data is used to identify the user, identify “lookalike” customers, and for security and fraud prevention.</li>
                <li>Commercial Information We do not collect payment card numbers and pins. Checkout happens through a third-party portal of our payment gateway. We are provided details of the mode of payment and the bank used. We may use this information to provide targeted discounts and promotions and to analyze sources of traffic </li>
                <li>Internet and network activity like IP addresses and location We collect this data automatically when you visit our website. This data is used to analyze location-based aggregate data and sources of traffic. We also use this data to provide goods or services and targeted promotions.</li>
                <li>Communication data We store all call records and WhatsApp communication with users. This is used for quality, training purposes, and for safety and fraud prevention</li>
                <li>Social Media reviews We may choose to reshare customer reviews on Facebook, Instagram, and other social media. We use this data to measure user engagement and for security and fraud prevention. We obtain this data directly when users share our content, post with our products, or tag us.</li>
                <li>Other sources We may use other sources to draw data to identify customers, deliver goods, customize and personalize advertisements, identify new customers, and for security and fraud prevention</li>
              </ul>
              <h2>Security</h2>
              <ul>
                <li>Data Sharing The Royal Chitran does not share user data with third parties in exchange for money. We transfer personal data to third parties in order to obtain their services (for example, for Facebook and Google Ads). We are always careful with user data and we intend to comply with all regional legislation</li>
                <li>Use of Cookies The Royal Chitran uses cookies to improve user experience and to increase website load speeds. Users are advised to check their web browser settings if they do not want to share cookies. Some of the user tracking are done by Google and Facebook. You can refer to Google and Facebook’s privacy policy for reference.</li>
                <li>External Links Although The Royal Chitran only includes verified links on the website, The Royal Chiran does not take guarantee for the quality of the links. Users are advised to exercise caution while clicking on any links. The Royal Chitran cannot be held liable for any damage caused due to external links.</li>
              </ul>
            </div>
          </div>
        </div>
        </div>
      </Container>
    </section>      
  )
}

export default PrivacyPolicy