import React from 'react';
import {Container, Row, Col, Image} from 'react-bootstrap';
import BootstrapForm from '../BootstrapForm';
import 'bootstrap/dist/css/bootstrap.min.css';
import Banner from '../Banner';


const ThankYou = () => {
  return (
      <section className="sp-cnt-blk pongal png-border-btm franchise">            
        <Container>
            <div>
              <div className="sp-cnt-in cul-pk text-center">
                <div className="pongal-time">    
                  <div className="ltd-fd-bx">
                  <h3>Thank You</h3>
                  </div>
                </div>
                <p>Thank you for reaching out! We will contact you as soon as possible.</p>
              </div>	
            </div>
       
          <div className="pongal-btm">
            <Row>
            <Col className="" sm={6}>
              <p>Booking are not required</p>
              <p>No refund and transfer</p>
              <p>Pure veg. Non Alcoholic & Non Smoking Zone</p>
            </Col>
            <Col className="" sm={6}>
                <div className='whatsapp-blk'>
                  <p>For more updates please</p>
                  <div className='btn-whatsapp'><a href='https://wa.me/9884334346' target='_blank'><Image src="../images/ic-whatsapp.png" /> 9884334346</a></div>
                </div>
            </Col>
            </Row>
          </div>
      </Container>
    </section>      
  )
}

export default ThankYou